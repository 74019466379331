//
//
//
//
//
//
//
//
//
//
//
//
//

import 'onsenui/esm/elements/ons-carousel';
import { hasOptions, deriveEvents } from '../mixins';

export default {
  name: 'v-ons-carousel',
  mixins: [hasOptions, deriveEvents],

  props: {
    index: {
      type: Number
    }
  },

  watch: {
    index() {
      if (this.index !== this.$el.getActiveIndex()) {
        this.$el.setActiveIndex(this.index, this.options);
      }
    }
  }
};

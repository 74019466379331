//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'onsenui/esm/elements/ons-tabbar';
import { deriveEvents, hasOptions, hidable, selfProvider, modifier } from '../mixins';

export default {
  name: 'v-ons-tabbar',
  mixins: [deriveEvents, hasOptions, hidable, selfProvider, modifier],

  props: {
    index: {
      type: Number
    },
    tabs: {
      type: Array,
      validator(value) {
        return value.every(tab => ['icon', 'label', 'page'].some(prop => !!Object.getOwnPropertyDescriptor(tab, prop)));
      }
    },
    tabbarStyle: {
      type: null
    }
  },

  methods: {
    _tabKey(tab) {
      return tab.key || tab.label || tab.icon;
    }
  },

  watch: {
    index() {
      if (this.index !== this.$el.getActiveTabIndex()) {
        this.$el.setActiveTab(this.index, { reject: false, ...this.options });
      }
    }
  }
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'onsenui/esm/elements/ons-alert-dialog';
import { hasOptions, dialogCancel, deriveEvents, deriveDBB, portal, modifier } from '../mixins';

export default {
  name: 'v-ons-alert-dialog',
  mixins: [hasOptions, dialogCancel, deriveEvents, deriveDBB, portal, modifier],

  props: {
    title: {
      type: String
    },
    footer: {
      type: Object,
      validator(value) {
        return Object.keys(value).every(key => value[key] instanceof Function);
      }
    }
  }
};

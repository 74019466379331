//
//
//
//
//
//
//
//
//
//

import 'onsenui/esm/elements/ons-page';
import { deriveEvents, deriveDBB, modifier } from '../mixins';

export default {
  name: 'v-ons-page',
  mixins: [deriveEvents, deriveDBB, modifier],

  props: {
    infiniteScroll: {
      type: Function
    }
  }
};

//
//
//
//
//
//

import 'onsenui/esm/elements/ons-segment';
import { deriveEvents } from '../mixins';

export default {
  name: 'v-ons-segment',
  mixins: [deriveEvents],

  props: {
    index: {
      type: Number
    }
  },

  watch: {
    index() {
      if (this.index !== this.$el.getActiveButtonIndex()) {
        this.$el.setActiveButton(this.index, { reject: false });
      }
    }
  }
};

//
//
//
//
//
//
//
//
//
//

import 'onsenui/esm/elements/ons-pull-hook';
import { deriveEvents } from '../mixins';

export default {
  name: 'v-ons-pull-hook',
  mixins: [deriveEvents],

  props: {
    action: {
      type: Function
    },
    onPull: {
      type: Function
    }
  }
};
